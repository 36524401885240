const qanda = [
  {
    id: 1,
    question: "pitanje 1",
    answer: "odgovor 1",
    photo: "././photos/amy-humphries-AllEP6K_TAg-unsplash.jpg",
  },
  {
    id: 2,
    question: "pitanje 2",
    answer: "odgovor 2",
    photo: "././photos/ricky-kharawala-adK3Vu70DEQ-unsplash.jpg",
  },
  {
    id: 3,
    question: "pitanje 3",
    answer: "odgovor 3",
    photo: "././photos/lydia-tan-f5KQq4Wfxg8-unsplash.jpg",
  },
  {
    id: 4,
    question: "pitanje 4",
    answer: "odgovor 4",
    photo: "././photos/paul-hanaoka-w2DsS-ZAP4U-unsplash.jpg",
  },
  {
    id: 5,
    question: "pitanje 5",
    answer: "odgovor 5",
    photo: "././photos/ejsoq-ZFid7KPqPbo-unsplash.jpg",
  },
  {
    id: 6,
    question: "pitanje 6",
    answer: "odgovor 6",
    photo: "././photos/luiza-sayfullina-9giow4jXrzM-unsplash.jpg",
  },
  {
    id: 7,
    question: "pitanje 7",
    answer: "odgovor 7",
    photo: "././photos/olga-andreyanova-pgrJI-Rijgk-unsplash.jpg",
  },
  {
    id: 8,
    question: "pitanje 8",
    answer: "odgovor 8",
    photo: "././photos/rana-sawalha-X7UR0BDz-UY-unsplash.jpg",
  },
  {
    id: 9,
    question: "pitanje 9",
    answer: "odgovor 9",
    photo: "././photos/richard-brutyo-Sg3XwuEpybU-unsplash.jpg",
  },
  {
    id: 10,
    question: "pitanje 10",
    answer: "odgovor 10",
    photo: "././photos/jamie-street-wcO2PWLuQ3U-unsplash.jpg",
  },
];
export default qanda;