import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";

function Paw() {
  return (
    <span className="paw"> <FontAwesomeIcon icon={faPaw}/></span>
  );
}

export default Paw
