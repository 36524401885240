const photos = [
  {
    id: 1,
    photo: "././photos/gallery/sa_kragnom.jpeg",
    title: "",
  },
  {
    id: 2,
    photo: "././photos/gallery/pas-4.jpg",
    title: "",
  },
  {
    id: 3,
    photo: "././photos/gallery/beli-pas.jpeg",
    title: "",
  },

  {
    id: 4,
    photo: "././photos/gallery/pas-2.jpg",
    title: "",
  },
  {
    id: 5,
    photo: "././photos/gallery/prijem.jpeg",
    title: "",
  },
  {
    id: 6,
    photo: "././photos/gallery/1-papagaj.jpg",
    title: "Žuća",
  },
  {
    id: 7,
    photo: "././photos/gallery/bez-kragne.jpeg",
    title: "",
  },
  {
    id: 8,
    photo: "././photos/gallery/maca.jpg",
    title: "",
  },
  {
    id: 9,
    photo: "././photos/gallery/Bela.jpg",
    title: "Bela",
  },
  {
    id: 10,
    photo: "././photos/gallery/hirurgija.jpeg",
    title: " ",
  },
  {
    id: 11,
    photo: "././photos/gallery/crno-beli-pas.jpg",
    title: " ",
  },

  {
    id: 12,
    photo: "././photos/gallery/mali-crni-pas.jpeg",
    title: " ",
  },
  {
    id: 13,
    photo: "././photos/gallery/ovcar.jpeg",
    title: " ",
  },
  {
    id: 14,
    photo: "././photos/gallery/pas-cekaonica.jpeg",
    title: " ",
  },
  {
    id: 15,
    photo: "././photos/gallery/pregled.jpeg",
    title: " ",
  },
  {
    id: 16,
    photo: "././photos/gallery/eksterijer-1.jpg",
    title: " ",
  },
  {
    id: 17,
    photo: "././photos/gallery/znak-logo.jpeg",
    title: "",
  },
  {
    id: 18,
    photo: "././photos/gallery/zec.jpeg",
    title: "",
  },
  {
    id: 19,
    photo: "././photos/gallery/apoteka.jpeg",
    title: "",
  },
  {
    id: 20,
    photo: "././photos/gallery/beli.jpg",
    title: "",
  },
  {
    id: 21,
    photo: "././photos/gallery/braon-beli.jpg",
    title: "",
  },
  {
    id: 22,
    photo: "././photos/gallery/dijagnostika.jpg",
    title: "",
  },
  {
    id: 23,
    photo: "././photos/gallery/figurica.jpg",
    title: "",
  },
  {
    id: 24,
    photo: "././photos/gallery/grooming.jpg",
    title: "",
  },
  {
    id: 25,
    photo: "././photos/gallery/haski.jpg",
    title: "",
  },
  {
    id: 26,
    photo: "././photos/gallery/hirurgija-vrata.jpg",
    title: "",
  },
  {
    id: 27,
    photo: "././photos/gallery/kornjaca.jpg",
    title: "",
  },
  {
    id: 28,
    photo: "././photos/gallery/maca-2.jpg",
    title: "",
  },
  {
    id: 29,
    photo: "././photos/gallery/mainkoon.jpg",
    title: "",
  },
  {
    id: 30,
    photo: "././photos/gallery/mali-beli.jpg",
    title: "",
  },
  {
    id: 31,
    photo: "././photos/gallery/pudla.jpg",
    title: "",
  },
  {
    id: 32,
    photo: "././photos/gallery/pugs.jpg",
    title: "",
  }
  
];
export default photos;