const articles = [
  {
    id: 1,
    img: [
      "././photos/jametlene-reskp-VDrErQEF9e4-unsplash.jpg",
      "././photos/jamie-street-wcO2PWLuQ3U-unsplash.jpg",
      "././photos/krista-mangulsone-9gz3wfHr65U-unsplash.jpg",
    ],
    title: "article 1",
    text: "article text 1",
  },
  {
    id: 2,
    img: [
      "././photos/paul-hanaoka-w2DsS-ZAP4U-unsplash.jpg",
      "././photos/priscilla-du-preez-2hc6ocDAsNY-unsplash.jpg",
      "././photos/krista-mangulsone-9gz3wfHr65U-unsplash.jpg",
    ],
    title: "article 2",
    text: "article text 2",
  },
  {
    id: 3,
    img: [
      "././photos/krista-mangulsone-9gz3wfHr65U-unsplash.jpg",
      "././photos/paul-hanaoka-w2DsS-ZAP4U-unsplash.jpg",
      "././photos/priscilla-du-preez-2hc6ocDAsNY-unsplash.jpg",
    ],
    title: "article 3",
    text: "article text 3",
  },
];
export default articles;
